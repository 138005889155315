import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, Observable, throwError } from 'rxjs';

export interface Contact {
  id: string;
  name: string;
  email: string;
  phone: string;
  city: string;
  address: string;
  source: string;
  createdAt: string;
  updatedAt: string;
  reachOutAt?: string;
  userId?: string;
  facebookContactDataId?: {};
  status: string;
  accountId: string;
}

export interface AllContactsResponse {
  data: Contact[];
  meta: {
    total: number;
    page: number;
    limit: number;
    totalPages: number;
  };
}

@Injectable({ providedIn: 'root' })
export class ContactService {
  private apiRoute = '/contact';

  constructor(private http: HttpClient) {}

  public getAllContacts(
    page: number,
    limit: number,
  ): Observable<AllContactsResponse> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString());

    return this.http
      .get<AllContactsResponse>(
        `${environment.backendBaseUrl}${this.apiRoute}`,
        { params },
      )
      .pipe(
        catchError((error) => {
          console.error('Error fetching contacts:', error);
          return throwError(
            () => new Error('Failed to load contacts, please try again later.'),
          );
        }),
      );
  }
}
