import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { environment } from '../../environments/environment';
import { catchError, map, Observable, of } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private apiRoute = '/auth';

  isLoggedIn = signal(false);
  loggedInUser = signal(null);

  constructor(
    private readonly http: HttpClient,
    private readonly jwtHelperService: JwtHelperService,
  ) {
    this.isLoggedIn.set(!!localStorage.getItem('JWT_TOKEN'));
    if (this.isAuthenticated()) {
      this.loggedInUser.set(
        jwtHelperService.decodeToken(localStorage.getItem('JWT_TOKEN')),
      );
    }
  }

  public credentialsSignup(data: {
    name: string;
    email: string;
    password: string;
  }): Observable<any> {
    return this.http.post(
      `${environment.backendBaseUrl}${this.apiRoute}/register`,
      data,
    );
  }

  public credentialsLogin(data: {
    email: string;
    password: string;
  }): Observable<boolean> {
    return this.http
      .post(`${environment.backendBaseUrl}${this.apiRoute}/login`, data)
      .pipe(
        map((response: { user: any; token: string }) => {
          localStorage.setItem('JWT_TOKEN', response.token);
          this.loggedInUser.set(
            this.jwtHelperService.decodeToken(response.token),
          );
          this.isLoggedIn.set(true);
          return true;
        }),
        catchError((error) => {
          console.error(error);
          this.isLoggedIn.set(false);
          return of(false);
        }),
      );
  }

  public logout() {
    localStorage.removeItem('JWT_TOKEN');
    this.isLoggedIn.set(false);
    this.loggedInUser.set(null);
  }

  public isAuthenticated() {
    return this.isLoggedIn();
  }

  public getLoggedInUser() {
    return this.loggedInUser();
  }
}
