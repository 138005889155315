import {
  Component,
  HostBinding,
  signal,
  OnInit,
} from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { LucideAngularModule } from 'lucide-angular';
import { AvatarComponent } from '../avatar/avatar.component';
import { NgClass } from '@angular/common';
import { SharedService } from '../../services/shared.service';
import { DeviceSize } from '../../models/device-size.model';

export type MenuItem = {
  path: string;
  text: string;
  icon: string;
};

export type userDetails = {
  src: string;
  userName: string;
  email: string;
};

@Component({
  selector: 'app-side-panel',
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    LucideAngularModule,
    AvatarComponent,
    NgClass,
  ],
  templateUrl: './side-panel.component.html',
  styleUrl: './side-panel.component.scss',
})
export class SidePanelComponent implements OnInit {
  isSidePanelClosed = signal(false);

  menuItems = signal<MenuItem[]>([
    {
      path: 'dashboard',
      text: 'דף הבית',
      icon: 'home',
    },
    {
      path: 'leads',
      text: 'לידים',
      icon: 'book-user',
    },
    {
      path: 'reports',
      text: 'דוחות',
      icon: 'clipboard-plus',
    },
    {
      path: 'tasks',
      text: 'משימות',
      icon: 'list-todo',
    },

    {
      path: 'sales',
      text: 'מכירות',
      icon: 'handshake',
    },
    {
      path: 'integrations',
      text: 'אינטגרציות',
      icon: 'blocks',
    },
  ]);

  @HostBinding('class.closed') get isClosed() {
    return this.isSidePanelClosed();
  }
  sizes!: DeviceSize;

  constructor(private readonly sharedService: SharedService) {}

  ngOnInit() {
    this.sizes = this.sharedService.sizes;
    if (!this.sizes.isMobile) {
      const storedState = sessionStorage.getItem('sidePanelClosed');
      if (storedState !== null) {
        this.isSidePanelClosed.set(JSON.parse(storedState));
      }
    }
  }

  toggleSidePanel() {
    const newState = !this.isSidePanelClosed();
    this.isSidePanelClosed.set(newState);
    sessionStorage.setItem('sidePanelClosed', JSON.stringify(newState));
  }
}
