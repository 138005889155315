import { invariant as n, defaultTo as t, assign as u, optionalFunctionValue as r } from "vest-utils";
const e = "Not inside of a running context.",
  o = Symbol();
function c(u) {
  let r = o;
  return {
    run: function (n, t) {
      const u = i() ? c() : o;
      r = n;
      const e = t();
      return r = u, e;
    },
    use: c,
    useX: function (u) {
      return n(i(), t(u, e)), r;
    }
  };
  function c() {
    return i() ? r : u;
  }
  function i() {
    return r !== o;
  }
}
function i(n) {
  const t = c();
  return {
    bind: function (n, t) {
      return function (...u) {
        return e(n, function () {
          return t(...u);
        });
      };
    },
    run: e,
    use: t.use,
    useX: t.useX
  };
  function e(e, o) {
    var c;
    const i = t.use(),
      s = u({}, i || {}, null !== (c = r(n, e, i)) && void 0 !== c ? c : e);
    return t.run(Object.freeze(s), o);
  }
}
export { i as createCascade, c as createContext };
