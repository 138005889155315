<div class="separator">
  <h3>התחבר לחשבון שלך</h3>
  <form
    scVestForm
    (ngSubmit)="onSubmit()"
    (formValueChange)="formValue.set($event)"
    [formValue]="formValue()"
    [suite]="suite"
  >
    <fieldset>
      <div class="input-wrapper" sc-control-wrapper>
        <input
          name="email"
          class="auth-input"
          [ngModel]="formValue().email"
          type="email"
          placeholder='כתובת דוא"ל'
        />
        <lucide-icon name="mail" [size]="24"></lucide-icon>
      </div>
      <div class="input-wrapper" sc-control-wrapper>
        <input
          name="password"
          class="auth-input"
          [ngModel]="formValue().password"
          type="password"
          placeholder="סיסמה"
        />
        <lucide-icon name="lock-keyhole" [size]="24"></lucide-icon>
      </div>

      <div class="input-wrapper" sc-control-wrapper>
        <div class="checkbox-wrapper">
          <input
            type="checkbox"
            id="remember_me"
            name="remember_me"
            [ngModel]="formValue().remember_me"
          />
          <label for="remember_me">אל תשכחו אותי!</label>
        </div>
      </div>

      <input class="submit-btn" type="submit" value="המשך להתחברות" />
    </fieldset>
  </form>

  <div class="or-signup-with">
    <p class="lines">או התחבר עם</p>
    <div class="oauth-btn-wrapper">
      <a class="oauth-btn" [href]="backendBaseUrl + '/auth/google/connect'">
        <img src="assets/google-logo.svg" />גוגל
      </a>
      <button class="oauth-btn">
        <img src="assets/facebook-logo.svg" />פייסבוק
      </button>
    </div>

    <p class="register-link">
      עוד לא נרשמת?
      <a routerLink="/auth/signup" class="link">הרשם כאן</a>
    </p>
  </div>
</div>
