import { Component, OnInit } from '@angular/core';
import { SidePanelComponent } from '../../components/side-panel/side-panel.component';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from '../../components/header/header.component';
import { SharedService } from '../../services/shared.service';
import { DeviceSize } from '../../models/device-size.model';

@Component({
  selector: 'app-main',
  standalone: true,
  imports: [CommonModule, HeaderComponent, SidePanelComponent, RouterOutlet],
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss',
})
export class MainComponent implements OnInit {
  sizes!: DeviceSize;
  constructor(private readonly sharedService: SharedService) {}
  ngOnInit() {
    this.sizes = this.sharedService.sizes;
  }
}
