<header>
  @if (sizes.isMobile) {
    <button (click)="toggleSidePanel()">
      <lucide-icon name="menu" [size]="32"></lucide-icon>
    </button>
  }

  <a routerLink="/" class="navbar-logo">
    <img src="/assets/goren.png" width="32" height="32" alt="logo" />
  </a>

  <app-avatar
    [ngClass]="{ 'img-only': true }"
    [src]="avatar().src"
    [userName]="avatar().userName"
    [email]="avatar().email"
  ></app-avatar>
</header>

@if (isSidePanelOpen() && sizes.isMobile) {
  <div class="menu">
    <app-header-mobile-dropdown (closeDropdown)="closeDropdown($event)"></app-header-mobile-dropdown>
  </div>
}
