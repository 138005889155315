<a routerLink="/" class="logo-wrapper">
  <h2>EngageFlow</h2>
  <img src="assets/goren.png" alt="logo" height="34" width="34" />
</a>

<div class="carousel-wrapper">
  <div class="image-wrapper">
    <img src="assets/auth-banner.svg" height="409" width="343" />
    <div class="sm-circle-bg"></div>
    <div class="lg-circle-bg"></div>
  </div>

  <div class="banner-text">
    <h3>Customizable Multipurpose Dashboard</h3>
    <p>Everything you need in an easily customizable dashboard</p>

    <div class="carousel-dots">
      <span class="dot"></span>
      <span class="dot"></span>
      <span class="dot active"></span>
    </div>
  </div>
</div>
