<div class="form-wrapper">
  <a routerLink="/" class="logo-wrapper">
    <h2>EngageFlow</h2>
    <img src="assets/goren.png" alt="logo" height="48" width="48" />
  </a>

  <div class="form-inner-wrapper">
    <router-outlet></router-outlet>
  </div>
</div>

<app-auth-banner></app-auth-banner>
