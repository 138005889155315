import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CloundinaryService {
    cloudinaryBucket = `${environment.cloudinary.url}/${environment.cloudinary.bucketName}`;
  constructor(private http: HttpClient) {}

  private prepateFileForCloudinary(file: File): FormData {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('folder', environment.cloudinary.folder);
    formData.append('upload_preset', environment.cloudinary.preset);
    return formData;
  }

  public uploadImage(file: File): Observable<any> {
    const fileToUpload = this.prepateFileForCloudinary(file);
    return this.http.post(
      `${this.cloudinaryBucket}/image/upload`,
      fileToUpload,
    );
  }
}
