import { isNullish as t, isNotNullish as e, invariant as n, assign as r, bus as o, optionalFunctionValue as i, tinyState as s, deferThrow as u, text as l, isPromise as a, isStringValue as c, hasOwnProperty as d } from "vest-utils";
import { createCascade as f } from "context";
import { expandObject as y, minifyObject as E } from "vest-utils/minifyObject";
const p = {
  ASYNC_ISOLATE_DONE: "ASYNC_ISOLATE_DONE",
  ISOLATE_DONE: "ISOLATE_DONE",
  ISOLATE_ENTER: "ISOLATE_ENTER",
  ISOLATE_PENDING: "ISOLATE_PENDING"
};
var O;
"function" == typeof SuppressedError && SuppressedError, function (t) {
  t.NO_ACTIVE_ISOLATE = "Not within an active isolate", t.UNABLE_TO_PICK_NEXT_ISOLATE = "Unable to pick next isolate. This is a bug, please report it to the Vest maintainers.", t.ENCOUNTERED_THE_SAME_KEY_TWICE = 'Encountered the same key "{key}" twice. This may lead to inconsistent or overriding of results.', t.INVALID_ISOLATE_CANNOT_PARSE = "Invalid isolate was passed to IsolateSerializer. Cannot proceed.";
}(O || (O = {}));
class v {
  static at(e, n) {
    var r, o;
    return t(e) ? null : null !== (o = null === (r = e.children) || void 0 === r ? void 0 : r[n]) && void 0 !== o ? o : null;
  }
  static cursor(e) {
    var n, r;
    return t(e) ? 0 : null !== (r = null === (n = e.children) || void 0 === n ? void 0 : n.length) && void 0 !== r ? r : 0;
  }
  static canReorder(e) {
    return !t(e) && v.allowsReorder(e.parent);
  }
  static allowsReorder(t) {
    return !0 === (null == t ? void 0 : t.allowReorder);
  }
  static usesKey(n) {
    return !t(n) && e(n.key);
  }
  static getChildByKey(e, n) {
    var r, o;
    return t(e) ? null : null !== (o = null === (r = e.keys) || void 0 === r ? void 0 : r[n]) && void 0 !== o ? o : null;
  }
}
class h {
  static setParent(t, e) {
    return t.parent = e, t;
  }
  static saveOutput(t, e) {
    return t.output = e, t;
  }
  static setKey(t, e) {
    return t.key = e, t;
  }
  static addChild(t, e) {
    var r;
    n(t), t.children = null !== (r = t.children) && void 0 !== r ? r : [], t.children.push(e), h.setParent(e, t);
  }
  static removeChild(t, e) {
    var n, r;
    t.children = null !== (r = null === (n = t.children) || void 0 === n ? void 0 : n.filter(t => t !== e)) && void 0 !== r ? r : null;
  }
  static addChildKey(t, e, r) {
    var o;
    n(t), t.keys = null !== (o = t.keys) && void 0 !== o ? o : {}, t.keys[e] = r;
  }
  static slice(e, n) {
    t(e.children) || (e.children.length = n);
  }
  static setData(t, e) {
    t.data = e;
  }
  static abort(e, n) {
    t(e.abortController) || e.abortController.abort(n);
  }
}
const _ = f((t, e) => {
    if (e) return null;
    n(t.historyRoot);
    const [o] = t.historyRoot(),
      i = {};
    return r(i, {
      historyNode: o,
      runtimeNode: null,
      runtimeRoot: null,
      stateRef: t
    }), i;
  }),
  N = _.run,
  T = {
    Run: N,
    createRef: function (t, e) {
      return Object.freeze({
        Bus: o.createBus(),
        Reconciler: t,
        appData: i(e),
        historyRoot: s.createTinyState(null)
      });
    },
    persist: I,
    reset: function () {
      const [,, t] = S();
      t();
    },
    useAvailableRoot: function () {
      const t = k();
      if (t) return t;
      const [e] = S();
      return e;
    },
    useCurrentCursor: function () {
      const t = m();
      return t ? v.cursor(t) : 0;
    },
    useHistoryRoot: S,
    useSetHistoryRoot: b,
    useSetNextIsolateChild: L,
    useXAppData: function () {
      return A().stateRef.appData;
    }
  };
function I(t) {
  const e = _.useX();
  return (...n) => {
    var r;
    const o = null !== (r = _.use()) && void 0 !== r ? r : e;
    return _.run(o.stateRef, () => t(...n));
  };
}
function A() {
  return _.useX();
}
function S() {
  return A().stateRef.historyRoot();
}
function R() {
  return A().historyNode;
}
function C() {
  const t = m(),
    e = R();
  return t ? v.at(e, v.cursor(t)) : e;
}
function b(t) {
  const [, e] = S();
  e(t);
}
function m() {
  var t;
  return null !== (t = A().runtimeNode) && void 0 !== t ? t : null;
}
function k() {
  return A().runtimeRoot;
}
function L(t) {
  const e = m();
  n(e, O.NO_ACTIVE_ISOLATE), h.addChild(e, t), h.setParent(t, e);
}
function D() {
  return A().stateRef.Bus;
}
function K(e, n) {
  const r = D().emit;
  return t(e) || r(e, n), I(r);
}
var P,
  w = Object.freeze({
    __proto__: null,
    useBus: D,
    useEmit: K,
    usePrepareEmitter: function (t) {
      const e = K();
      return n => e(t, n);
    }
  });
!function (t) {
  t.Type = "$type", t.Keys = "keys", t.Key = "key", t.Parent = "parent", t.Data = "data", t.AllowReorder = "allowReorder", t.Status = "status", t.AbortController = "abortController", t.Children = "children";
}(P || (P = {}));
const g = new Set([P.AbortController, P.Parent, P.Keys]);
function j(t, e) {
  return (null == t ? void 0 : t[P.Type]) === e;
}
function B(t, e) {
  return j(t, e[P.Type]);
}
var x = Object.freeze({
  __proto__: null,
  isIsolateType: j,
  isSameIsolateIdentity: function (t, e) {
    return Object.is(t, e) || B(t, e) && t.key === e.key;
  },
  isSameIsolateType: B
});
class z {
  static reconcile(e) {
    const r = function (e, n) {
      var r;
      if (t(n)) return function (t) {
        if (v.usesKey(t)) return z.handleIsolateNodeWithKey(t, !1);
        return t;
      }(e);
      if (!B(e, n)) return e;
      const o = A().stateRef.Reconciler;
      return null !== (r = o(e, n)) && void 0 !== r ? r : function (e, n) {
        return t(n), e;
      }(e, n);
    }(e, C());
    return n(r, O.UNABLE_TO_PICK_NEXT_ISOLATE), r;
  }
  static dropNextNodesOnReorder(t, e, n) {
    const r = t(e, n);
    return r && function () {
      const t = m(),
        e = R();
      if (!e || !t) return;
      h.slice(e, v.cursor(t));
    }(), r;
  }
  static handleIsolateNodeWithKey(e, r) {
    n(v.usesKey(e));
    const o = function (e) {
      if (t(e)) return null;
      const n = A().historyNode;
      return v.getChildByKey(n, e);
    }(e.key);
    let s = e;
    return t(o) || i(r, o) || (s = o), function (e, r) {
      if (!e) return;
      const o = m();
      n(o, O.NO_ACTIVE_ISOLATE), t(v.getChildByKey(o, e)) ? h.addChildKey(o, e, r) : u(l(O.ENCOUNTERED_THE_SAME_KEY_TWICE, {
        key: e
      }));
    }(e.key, s), s;
  }
}
class V {
  static create(t, e, n = void 0, r) {
    const o = m(),
      i = h.setParent(function (t, e, n = null) {
        const r = null != e ? e : {},
          {
            allowReorder: o,
            status: i
          } = r,
          s = function (t, e) {
            var n = {};
            for (var r in t) Object.prototype.hasOwnProperty.call(t, r) && e.indexOf(r) < 0 && (n[r] = t[r]);
            if (null != t && "function" == typeof Object.getOwnPropertySymbols) {
              var o = 0;
              for (r = Object.getOwnPropertySymbols(t); o < r.length; o++) e.indexOf(r[o]) < 0 && Object.prototype.propertyIsEnumerable.call(t, r[o]) && (n[r[o]] = t[r[o]]);
            }
            return n;
          }(r, ["allowReorder", "status"]);
        return Object.assign(Object.assign({
          [P.AllowReorder]: o,
          [P.AbortController]: new AbortController(),
          [P.Keys]: null,
          [P.Parent]: null,
          [P.Type]: t,
          [P.Data]: s
        }, i && {
          [P.Status]: i
        }), {
          children: null,
          key: n,
          output: null
        });
      }(t, n, r), o),
      s = z.reconcile(i),
      u = C(),
      l = Object.is(s, i);
    o && L(s);
    const c = l ? function (t, e, n) {
      const r = k(),
        o = K(),
        i = N(Object.assign({
          historyNode: t,
          runtimeNode: e
        }, !r && {
          runtimeRoot: e
        }), () => {
          o(p.ISOLATE_ENTER, e);
          const t = n(e);
          return a(t) ? (o(p.ISOLATE_PENDING, e), t.then(t => {
            V.isIsolate(t) && h.addChild(e, t), o(p.ISOLATE_DONE, e), o(p.ASYNC_ISOLATE_DONE, e);
          })) : o(p.ISOLATE_DONE, e), t;
        });
      return e.output = i, i;
    }(u, i, e) : s.output;
    return h.saveOutput(s, c), o || b(s), s;
  }
  static isIsolate(t) {
    return e(t) && t[P.Type];
  }
}
function U(e, n, r) {
  var o;
  let s = !1;
  if (!s) {
    for (const t of null !== (o = e.children) && void 0 !== o ? o : []) if (U(t, (t, e) => {
      n(t, () => {
        e(), u();
      });
    }, r), s) return;
    (t(r) || i(r, e)) && n(e, u);
  }
  function u() {
    s = !0;
  }
}
function W(t, e, n) {
  let r = !1;
  return U(t, (t, n) => {
    e(t) && (n(), r = !0);
  }, n), r;
}
function X(t, e) {
  let n = t;
  do {
    if (e(n)) return n;
    n = n.parent;
  } while (n);
  return null;
}
var Y = Object.freeze({
  __proto__: null,
  closest: X,
  closestExists: function (t, e) {
    return !!X(t, e);
  },
  every: function (t, e, n) {
    let r = !0;
    return U(t, (t, n) => {
      e(t) || (n(), r = !1);
    }, n), r;
  },
  find: function (t, e, n) {
    let r = null;
    return U(t, (t, n) => {
      e(t) && (n(), r = t);
    }, n), r;
  },
  findAll: function (t, e, n) {
    const r = [];
    return U(t, t => {
      e(t) && r.push(t);
    }, n), r;
  },
  findClosest: function (t, e) {
    var n, r;
    let o = null,
      i = t;
    for (; i && (o = null !== (r = null === (n = i.children) || void 0 === n ? void 0 : n.find(e)) && void 0 !== r ? r : null, !o);) i = i.parent;
    return o;
  },
  has: function (t, e) {
    return W(t, () => !0, e);
  },
  pluck: function (t, e, n) {
    U(t, t => {
      e(t) && t.parent && h.removeChild(t.parent, t);
    }, n);
  },
  reduce: function (t, e, n, r) {
    let o = n;
    return U(t, (t, n) => {
      o = e(o, t, n);
    }, r), o;
  },
  some: W,
  walk: U
});
class H {
  static deserialize(t) {
    const e = c(t) ? JSON.parse(t) : Object.assign({}, t),
      n = y(...e);
    H.validateIsolate(n);
    const r = [n];
    for (; r.length;) {
      const t = r.shift();
      if (!t) continue;
      const e = t.children;
      e && (t.children = e.map(e => {
        var n;
        const o = Object.assign({}, e);
        h.setParent(o, t), r.push(o);
        const i = o.key;
        return i && (t.keys = null !== (n = t.keys) && void 0 !== n ? n : {}, t.keys[i] = o), o;
      }));
    }
    return n;
  }
  static serialize(e) {
    if (t(e)) return "";
    const n = E(e, g);
    return JSON.stringify(n);
  }
  static validateIsolate(t) {
    n(d(t, P.Type), l(O.INVALID_ISOLATE_CANNOT_PARSE));
  }
}
export { w as Bus, V as Isolate, v as IsolateInspector, h as IsolateMutator, x as IsolateSelectors, H as IsolateSerializer, z as Reconciler, p as RuntimeEvents, T as VestRuntime, Y as Walker };
