import { APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import * as Sentry from '@sentry/angular';
export const sentryProviders = [
  {
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler({
      showDialog: true,
    }),
  },
  {
    provide: Sentry.TraceService,
    deps: [RouterOutlet],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: () => () => {},
    deps: [Sentry.TraceService],
    multi: true,
  },
];
