import { Component, OnInit, signal } from '@angular/core';
import { createAccountForm } from '../../models/create-account.model';
import { LucideAngularModule } from 'lucide-angular';
import { NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { set, vestForms } from 'ngx-vest-forms';
import { CloundinaryService } from '../../services/cloudinary.service';
import { switchMap } from 'rxjs';
import { AccountService } from '../../services/account.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-create-account',
  standalone: true,
  imports: [LucideAngularModule, NgTemplateOutlet, NgOptimizedImage, vestForms],
  templateUrl: './create-account.component.html',
  styleUrl: './create-account.component.scss',
})
export class CreateAccountComponent {
  protected readonly formValue = signal<createAccountForm>({});
  selectedFile: File | undefined;
  cloudinarySrc?: string;
  loadingPercentage = 0;
  loggedInUserEmail = null;

  constructor(
    private readonly cloudinaryService: CloundinaryService,
    private readonly accountService: AccountService,
    private readonly authService: AuthService,
  ) {
    this.loggedInUserEmail = authService.getLoggedInUser().email;
    this.formValue.set({ companyEmail: this.loggedInUserEmail });
  }

  onFileSelected(event: Event) {
    this.loadingPercentage = 0;
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      this.selectedFile = input.files[0];
      if (!this.selectedFile) return;
      this.fileLoadAnimation();
    }
  }

  onSubmit() {
    if (!this.selectedFile) return;
    this.cloudinaryService
      .uploadImage(this.selectedFile)
      .pipe(
        switchMap((response) => {
          console.log(response);
          this.cloudinarySrc = response.secure_url;
          const form = this.formValue();
          return this.accountService.createAccount({
            name: form.name,
            companyEmail: form.companyEmail,
            companyPhone: form.companyPhone,
            accountLogo: this.cloudinarySrc,
          });
        }),
      )
      .subscribe();
  }

  fileLoadAnimation() {
    const interval = setInterval(() => {
      this.loadingPercentage += 1;
      if (this.loadingPercentage === 100) {
        clearInterval(interval);
      }
    }, 10);
    setTimeout(() => {
      this.cloudinarySrc = URL.createObjectURL(this.selectedFile as Blob);
    }, 1100);
  }
}
