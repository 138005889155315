import { Component, Input } from '@angular/core';
import { ToggleButtonComponent } from '../../../../components/toggle-button/toggle-button.component';
import { LucideAngularModule } from 'lucide-angular';
import { NgIf } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
export interface Integration {
  name: string;
  subtitle: string;
  image: {
    src: string;
    alt: string;
  };
}

@Component({
  selector: 'app-integration',
  standalone: true,
  imports: [ToggleButtonComponent, LucideAngularModule, NgIf],
  templateUrl: './integration.component.html',
  styleUrl: './integration.component.scss',
})
export class IntegrationComponent {
  @Input() integration: Integration;
  @Input() isIntegrationOn: boolean;

  backendBaseUrl = environment.backendBaseUrl;

  constructor(private readonly router: Router) {}

  handleIntegration(connectIntegration: boolean) {
    if (connectIntegration) {
      if (this.integration.name === 'Meta') {
        window.location.href = `${this.backendBaseUrl}/integration/facebook/connect`;
      }
    } else {
      console.log(`Disconnected ${this.integration.name}`);
    }
  }
}
