import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AccountService {
  private apiRoute = '/account';

  constructor(private http: HttpClient) {}

  public createAccount(data: any): any {
    return this.http.post(
      `${environment.backendBaseUrl}${this.apiRoute}`,
      data,
    );
  }

  public getAccount(): Observable<any> {
    return this.http.get(`${environment.backendBaseUrl}${this.apiRoute}`);
  }
}
