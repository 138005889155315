import { staticSuite, test, only, enforce, omitWhen } from 'vest';
import { SignUpFormModel } from '../models/signup-form.model';
import { isEmail } from 'validator';
import 'vest/enforce/email';
import { fromEvent, lastValueFrom, takeUntil } from 'rxjs';
import { UserService } from '../services/user.service';

enforce.extend({ isEmail });

export const createSignUpFormValidations = () => {
  return staticSuite((model: SignUpFormModel, field: string) => {
    only(field);

    test('fullName', 'שדה שם מלא הינו שדה חובה', () => {
      enforce(model.fullName).isNotBlank();
    });

    test('fullName', 'שדה שם מלא חייב להיות לפחות באורך 2 תווים', () => {
      enforce(model.fullName).longerThan(2);
    });

    test('fullName', 'שדה שם מלא לא יכול להיות ארוך מ60 תווים', () => {
      enforce(model.fullName).shorterThan(60);
    });

    // omitWhen(!model.email, () => {
    //   test('email', 'User id is already taken', async ({ signal }) => {
    //     await lastValueFrom(
    //       service
    //         .searchUserByEmail(model.email as string)
    //         .pipe(takeUntil(fromEvent(signal, 'abort'))),
    //     ).then(
    //       () => Promise.reject(),
    //       () => Promise.resolve(),
    //     );
    //   });
    // });

    test('email', 'שדה כתובת דוא"ל הינו שדה חובה', () => {
      enforce(model.email).isNotBlank();
    });

    test('email', 'כתובת הדוא"ל אינה תקינה', () => {
      enforce(model.email).isEmail();
    });

    test('passwords.password', 'שדה הסיסמה הינו שדה חובה', () => {
      enforce(model.passwords?.password).isNotBlank();
    });

    test('passwords.password', 'שדה הסיסמה חייב להכיל 8 תווים לפחות', () => {
      enforce(model.passwords?.password).longerThanOrEquals(8);
    });

    omitWhen(!model.passwords?.password, () => {
      test(
        'passwords.confirmPassword',
        'שדה אימות הסיסמה הינו שדה חובה',
        () => {
          enforce(model.passwords?.confirmPassword).isNotBlank();
        },
      );
    });

    omitWhen(
      !model.passwords?.password || !model.passwords?.confirmPassword,
      () => {
        test('passwords', 'שדה הסיסמה ואימות הסיסמה אינם תואמים', () => {
          enforce(model.passwords?.password).equals(
            model.passwords?.confirmPassword,
          );
        });
      },
    );

    test(
      'terms',
      'שדה אישור קריאת תנאי השימוש ומדיניות הפרטיות הינו חובה',
      () => {
        enforce(model.terms).isTruthy();
      },
    );
  });
};
