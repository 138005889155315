import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-toggle-button',
  standalone: true,
  templateUrl: './toggle-button.component.html',
  styleUrl: './toggle-button.component.scss',
})
export class ToggleButtonComponent {
  @Input() isChecked: boolean = false;
  @Output() toggled = new EventEmitter<boolean>();

  onToggle() {
    this.isChecked = !this.isChecked;
    this.toggled.emit(this.isChecked);
  }
}
