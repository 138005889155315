import { Injectable } from '@angular/core';
import {
  DeviceSize,
  MOBILE_MAX_WIDTH,
  TABLET_MAX_WIDTH,
} from '../models/device-size.model';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  sizes: DeviceSize;
  constructor() {
    this.initSizes();
  }

  initSizes() {
    const width = window.innerWidth;
    this.sizes = {
      isMobile: width <= MOBILE_MAX_WIDTH,
      isTablet: width > MOBILE_MAX_WIDTH && width <= TABLET_MAX_WIDTH,
      isDesktop: width > TABLET_MAX_WIDTH,
    };
  }
}
