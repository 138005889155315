import { enforce as t } from "n4s";
function e(t) {
  return e = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
    return typeof t;
  } : function (t) {
    return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
  }, e(t);
}
function n(t) {
  if (!("string" == typeof t || t instanceof String)) {
    var n = e(t);
    throw null === t ? n = "null" : "object" === n && (n = t.constructor.name), new TypeError("Expected a string but received a ".concat(n));
  }
}
function r() {
  var t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {},
    e = arguments.length > 1 ? arguments[1] : void 0;
  for (var n in e) void 0 === t[n] && (t[n] = e[n]);
  return t;
}
function o(t) {
  return o = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
    return typeof t;
  } : function (t) {
    return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
  }, o(t);
}
function i(t, e) {
  var r, i;
  n(t), "object" === o(e) ? (r = e.min || 0, i = e.max) : (r = arguments[1], i = arguments[2]);
  var a = encodeURI(t).split(/%..|./).length - 1;
  return a >= r && (void 0 === i || a <= i);
}
var a = {
  require_tld: !0,
  allow_underscores: !1,
  allow_trailing_dot: !1,
  allow_numeric_tld: !1,
  allow_wildcard: !1,
  ignore_max_length: !1
};
var c = "(?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])",
  l = "(".concat(c, "[.]){3}").concat(c),
  u = new RegExp("^".concat(l, "$")),
  f = "(?:[0-9a-fA-F]{1,4})",
  s = new RegExp("^(" + "(?:".concat(f, ":){7}(?:").concat(f, "|:)|") + "(?:".concat(f, ":){6}(?:").concat(l, "|:").concat(f, "|:)|") + "(?:".concat(f, ":){5}(?::").concat(l, "|(:").concat(f, "){1,2}|:)|") + "(?:".concat(f, ":){4}(?:(:").concat(f, "){0,1}:").concat(l, "|(:").concat(f, "){1,3}|:)|") + "(?:".concat(f, ":){3}(?:(:").concat(f, "){0,2}:").concat(l, "|(:").concat(f, "){1,4}|:)|") + "(?:".concat(f, ":){2}(?:(:").concat(f, "){0,3}:").concat(l, "|(:").concat(f, "){1,5}|:)|") + "(?:".concat(f, ":){1}(?:(:").concat(f, "){0,4}:").concat(l, "|(:").concat(f, "){1,6}|:)|") + "(?::((?::".concat(f, "){0,5}:").concat(l, "|(?::").concat(f, "){1,7}|:))") + ")(%[0-9a-zA-Z-.:]{1,})?$");
function _(t) {
  var e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "";
  return n(t), (e = String(e)) ? "4" === e ? u.test(t) : "6" === e && s.test(t) : _(t, 4) || _(t, 6);
}
var x = {
    allow_display_name: !1,
    require_display_name: !1,
    allow_utf8_local_part: !0,
    require_tld: !0,
    blacklisted_chars: "",
    ignore_max_length: !1,
    host_blacklist: [],
    host_whitelist: []
  },
  d = /^([^\x00-\x1F\x7F-\x9F\cX]+)</i,
  g = /^[a-z\d!#\$%&'\*\+\-\/=\?\^_`{\|}~]+$/i,
  m = /^[a-z\d]+$/,
  p = /^([\s\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e]|(\\[\x01-\x09\x0b\x0c\x0d-\x7f]))*$/i,
  F = /^[a-z\d!#\$%&'\*\+\-\/=\?\^_`{\|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+$/i,
  h = /^([\s\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|(\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*$/i;
t.extend({
  isEmail: function (t, e) {
    if (n(t), (e = r(e, x)).require_display_name || e.allow_display_name) {
      var o = t.match(d);
      if (o) {
        var c = o[1];
        if (t = t.replace(c, "").replace(/(^<|>$)/g, ""), c.endsWith(" ") && (c = c.slice(0, -1)), !function (t) {
          var e = t.replace(/^"(.+)"$/, "$1");
          if (!e.trim()) return !1;
          if (/[\.";<>]/.test(e)) {
            if (e === t) return !1;
            if (e.split('"').length !== e.split('\\"').length) return !1;
          }
          return !0;
        }(c)) return !1;
      } else if (e.require_display_name) return !1;
    }
    if (!e.ignore_max_length && t.length > 254) return !1;
    var l = t.split("@"),
      u = l.pop(),
      f = u.toLowerCase();
    if (e.host_blacklist.includes(f)) return !1;
    if (e.host_whitelist.length > 0 && !e.host_whitelist.includes(f)) return !1;
    var s = l.join("@");
    if (e.domain_specific_validation && ("gmail.com" === f || "googlemail.com" === f)) {
      var y = (s = s.toLowerCase()).split("+")[0];
      if (!i(y.replace(/\./g, ""), {
        min: 6,
        max: 30
      })) return !1;
      for (var b = y.split("."), v = 0; v < b.length; v++) if (!m.test(b[v])) return !1;
    }
    if (!(!1 !== e.ignore_max_length || i(s, {
      max: 64
    }) && i(u, {
      max: 254
    }))) return !1;
    if (!function (t, e) {
      n(t), (e = r(e, a)).allow_trailing_dot && "." === t[t.length - 1] && (t = t.substring(0, t.length - 1)), !0 === e.allow_wildcard && 0 === t.indexOf("*.") && (t = t.substring(2));
      var o = t.split("."),
        i = o[o.length - 1];
      if (e.require_tld) {
        if (o.length < 2) return !1;
        if (!e.allow_numeric_tld && !/^([a-z\u00A1-\u00A8\u00AA-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]{2,}|xn[a-z0-9-]{2,})$/i.test(i)) return !1;
        if (/\s/.test(i)) return !1;
      }
      return !(!e.allow_numeric_tld && /^\d+$/.test(i)) && o.every(function (t) {
        return !(t.length > 63 && !e.ignore_max_length || !/^[a-z_\u00a1-\uffff0-9-]+$/i.test(t) || /[\uff01-\uff5e]/.test(t) || /^-|-$/.test(t) || !e.allow_underscores && /_/.test(t));
      });
    }(u, {
      require_tld: e.require_tld,
      ignore_max_length: e.ignore_max_length
    })) {
      if (!e.allow_ip_domain) return !1;
      if (!_(u)) {
        if (!u.startsWith("[") || !u.endsWith("]")) return !1;
        var w = u.slice(1, -1);
        if (0 === w.length || !_(w)) return !1;
      }
    }
    if ('"' === s[0]) return s = s.slice(1, s.length - 1), e.allow_utf8_local_part ? h.test(s) : p.test(s);
    for (var $ = e.allow_utf8_local_part ? F : g, D = s.split("."), S = 0; S < D.length; S++) if (!$.test(D[S])) return !1;
    return !e.blacklisted_chars || -1 === s.search(new RegExp("[".concat(e.blacklisted_chars, "]+"), "g"));
  }
});
