import {
  Component,
  EventEmitter,
  HostBinding,
  Output,
  signal,
} from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { LucideAngularModule } from 'lucide-angular';
import { AvatarComponent } from '../../avatar/avatar.component';
import { NgClass } from '@angular/common';
import { SharedService } from '../../../services/shared.service';
import { DeviceSize } from '../../../models/device-size.model';

export type MenuItem = {
  path: string;
  text: string;
  icon: string;
};

export type userDetails = {
  src: string;
  userName: string;
  email: string;
};

@Component({
  selector: 'app-header-mobile-dropdown',
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    LucideAngularModule,
    AvatarComponent,
    NgClass,
  ],
  templateUrl: './header-mobile-dropdown.component.html',
  styleUrl: './header-mobile-dropdown.component.scss',
})
export class HeaderMobileDropdownComponent {
  isSidePanelClosed = signal(false);

  menuItems = signal<MenuItem[]>([
    {
      path: 'dashboard',
      text: 'דף הבית',
      icon: 'home',
    },
    {
      path: 'leads',
      text: 'לידים',
      icon: 'book-user',
    },
    {
      path: 'reports',
      text: 'דוחות',
      icon: 'clipboard-plus',
    },
    {
      path: 'tasks',
      text: 'משימות',
      icon: 'list-todo',
    },

    {
      path: 'sales',
      text: 'מכירות',
      icon: 'handshake',
    },
    {
      path: 'integrations',
      text: 'אינטגרציות',
      icon: 'blocks',
    },
  ]);

  @Output() closeDropdown = new EventEmitter<boolean>();

  constructor(private readonly sharedService: SharedService) {}

  emitCloseDropdown() {
    this.closeDropdown.emit(false);
  }
}
