<ul>
    <a routerLink="/apps/dashboard" class="logo-wrapper">
      <img src="/assets/goren.png" alt="logo" height="32" width="32" />
      @if (!isSidePanelClosed()) {
        <h2>EngageFlow</h2>
      }
    </a>
    @for (link of menuItems(); track link) {
      <li [routerLink]="link.path" routerLinkActive="active" (click)="emitCloseDropdown()">
        <lucide-icon [name]="link.icon" [size]="20"></lucide-icon>
        @if (!isSidePanelClosed()) {
          <a>{{ link.text }}</a>
        }
      </li>
    }
  </ul>
  
  <div class="side-panel-footer">
    <div>
      <button>
        <lucide-icon name="bell" [size]="20"></lucide-icon>
        @if (!isSidePanelClosed()) {
          התראות
        }
      </button>
      <a routerLink="/settings"
        ><lucide-icon name="cog" [size]="20"></lucide-icon>
        @if (!isSidePanelClosed()) {
          הגדרות
        }
      </a>
      <button>
        <lucide-icon name="log-out" [size]="20"></lucide-icon>
        @if (!isSidePanelClosed()) {
          התנתק
        }
      </button>
    </div>
   
  </div>
  

  
