function n(n) {
  return (...t) => !n(...t);
}
function t(n) {
  const t = String(n),
    r = Number(n),
    e = !isNaN(parseFloat(t)) && !isNaN(Number(n)) && isFinite(r);
  return Boolean(e);
}
const r = n(t);
function e(n, r) {
  return t(n) && t(r) && Number(n) === Number(r);
}
const u = n(e);
function o(n, t) {
  return e(n.length, t);
}
const i = n(o);
function c(n, r) {
  return t(n) && t(r) && Number(n) > Number(r);
}
function f(n, t) {
  return c(n.length, t);
}
function a(n = 1) {
  const t = [],
    r = (e, u) => {
      const o = r.get(e);
      if (o) return o[1];
      const i = u();
      return t.unshift([e.concat(), i]), f(t, n) && (t.length = n), i;
    };
  return r.invalidate = n => {
    const r = e(n);
    r > -1 && t.splice(r, 1);
  }, r.get = n => t[e(n)] || null, r;
  function e(n) {
    return t.findIndex(([t]) => o(n, t.length) && n.every((n, r) => n === t[r]));
  }
}
function l(n) {
  return null === n;
}
const s = n(l);
function v(n) {
  return void 0 === n;
}
const g = n(v);
function h(n) {
  return l(n) || v(n);
}
const b = n(h);
function p(n) {
  return [].concat(n);
}
function y(n) {
  return n.forEach(n => n());
}
function _(n, t) {
  return Object.prototype.hasOwnProperty.call(n, t);
}
function d(n) {
  return "function" == typeof n;
}
function N(n) {
  return !!n && d(n.then);
}
function m(n, ...t) {
  return d(n) ? n(...t) : n;
}
var O = Object.assign;
function j(n, t) {
  var r;
  return null !== (r = m(n)) && void 0 !== r ? r : m(t);
}
function w(n, t) {
  if (!n) throw t instanceof String ? t.valueOf() : new Error(t ? m(t) : t);
}
function S(n) {
  return String(n) === n;
}
function z(n, t) {
  return !!n != !!t;
}
function A(n) {
  return !!n === n;
}
function E(n) {
  setTimeout(() => {
    throw new Error(n);
  }, 0);
}
var $ = Object.freeze({
  __proto__: null,
  createBus: function () {
    const n = {};
    return {
      emit(n, r) {
        t(n).concat(t("*")).forEach(n => {
          n(r);
        });
      },
      on: (r, e) => (n[r] = t(r).concat(e), {
        off() {
          n[r] = t(r).filter(n => n !== e);
        }
      })
    };
    function t(t) {
      return n[t] || [];
    }
  }
});
const B = T();
function T(n) {
  return t = 0, () => `${n ? n + "_" : ""}${t++}`;
  var t;
}
function x(n, t) {
  let r = !1,
    e = null;
  for (let o = 0; o < n.length; o++) if (t(n[o], u, o), r) return e;
  function u(n, t) {
    n && (r = !0, e = t);
  }
}
function F(n) {
  return "object" == typeof n && !h(n);
}
function k(n) {
  return Boolean(Array.isArray(n));
}
const I = n(k);
function P(n) {
  return !n || (_(n, "length") ? o(n, 0) : !!F(n) && o(Object.keys(n), 0));
}
const q = n(P);
function C(n) {
  return c(n, 0);
}
const D = /{(.*?)}/g;
function G(n, ...t) {
  const r = t[0];
  if (F(r)) return n.replace(D, (n, t) => {
    var e;
    return `${null !== (e = r[t]) && void 0 !== e ? e : n}`;
  });
  const e = [...t];
  return n.replace(D, n => `${P(e) ? n : e.shift()}`);
}
function H(n) {
  let t = n.initial;
  return {
    getState: function () {
      return t;
    },
    initial: function () {
      return n.initial;
    },
    staticTransition: r,
    transition: function (n, e) {
      return t = r(t, n, e);
    }
  };
  function r(t, r, e) {
    var u, o, i;
    let c = null !== (o = null === (u = n.states[t]) || void 0 === u ? void 0 : u[r]) && void 0 !== o ? o : null === (i = n.states["*"]) || void 0 === i ? void 0 : i[r];
    if (Array.isArray(c)) {
      const [, n] = c;
      if (!n(e)) return t;
      c = c[0];
    }
    return c && c !== t ? c : t;
  }
}
function J(n, t) {
  return w(!h(n), t), n;
}
var K = Object.freeze({
  __proto__: null,
  createTinyState: function (n) {
    let t;
    return e(), () => [t, r, e];
    function r(n) {
      t = m(n, t);
    }
    function e() {
      r(m(n));
    }
  }
});
function L(n) {
  return new String(m(n));
}
function M() {}
var Q = Object.freeze({
  __proto__: null,
  all: function (...n) {
    return t => !P(n) && n.every(n => m(n, t));
  },
  any: function (...n) {
    return t => !P(n) && n.some(n => m(n, t));
  }
});
function R(...n) {
  return Object.freeze(O(...n));
}
export { Q as Predicates, H as StateMachine, L as StringObject, p as asArray, O as assign, n as bindNot, $ as bus, a as cache, y as callEach, j as defaultTo, E as deferThrow, z as either, R as freezeAssign, T as genSeq, c as greaterThan, _ as hasOwnProperty, w as invariant, k as isArray, A as isBoolean, P as isEmpty, d as isFunction, I as isNotArray, q as isNotEmpty, s as isNotNull, b as isNotNullish, r as isNotNumeric, g as isNotUndefined, l as isNull, h as isNullish, t as isNumeric, F as isObject, C as isPositive, N as isPromise, S as isStringValue, v as isUndefined, o as lengthEquals, i as lengthNotEquals, f as longerThan, x as mapFirst, J as nonnullish, M as noop, e as numberEquals, u as numberNotEquals, m as optionalFunctionValue, B as seq, G as text, K as tinyState };
