import { Component } from '@angular/core';
import { LucideAngularModule } from "lucide-angular";
@Component({
  selector: 'app-table-actions',
  standalone: true,
  imports: [
    LucideAngularModule
  ],
  templateUrl: './table-actions.component.html',
  styleUrl: './table-actions.component.scss'
})
export class TableActionsComponent {
  iconSize = 20

}
