import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForOf, NgIf, NgClass } from '@angular/common';
import { TableActionsComponent } from './table-actions/table-actions.component';
import { TagComponent, TagsType } from '../../../components/tag/tag.component';
import { LucideAngularModule } from 'lucide-angular';
import {
  Subject,
  debounceTime,
  distinctUntilChanged,
  takeUntil,
  catchError,
  of,
  map,
} from 'rxjs';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import {
  Contact,
  ContactService,
  AllContactsResponse,
} from '../../../services/contact.service';

interface Item extends Contact {
  selected?: boolean; // Add 'selected' field
}

@Component({
  standalone: true,
  templateUrl: 'table.component.html',
  styleUrl: 'table.component.scss',
  selector: 'app-team-table',
  imports: [
    NgForOf,
    NgIf,
    NgClass,
    TableActionsComponent,
    TagComponent,
    LucideAngularModule,
    NgxSkeletonLoaderModule,
  ],
})
export class TableComponent implements OnInit, OnDestroy {
  protected readonly TagsType = TagsType;

  items: Item[] = [];
  filteredItems: Item[] = [];
  displayedItems: Item[] = [];
  private searchTerms = new Subject<string>();
  private destroy$ = new Subject<void>();
  isLoading = true;
  noResultsFound = false;
  searchTerm = '';
  allSelected = false;

  // Pagination
  currentPage = 1;
  itemsPerPage = 10;
  totalItems = 0;

  constructor(private readonly contactService: ContactService) {}

  ngOnInit() {
    this.loadData();
    this.setupSearch();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // Load data from API
  private loadData() {
    this.isLoading = true;
    this.contactService
      .getAllContacts(this.currentPage, this.itemsPerPage)
      .pipe(
        takeUntil(this.destroy$),
        map((response: AllContactsResponse) => ({
          items: response.data.map((contact) => ({
            ...contact,
            selected: false,
          })),
          total: response.meta.total,
        })),
        catchError((error) => {
          console.error('Error fetching contacts:', error);
          return of({ items: [], total: 0 });
        }),
      )
      .subscribe({
        next: ({ items, total }) => {
          this.items = items;
          this.filteredItems = [...this.items];
          this.totalItems = total;
          this.updateDisplayedItems();
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  // Set up search debounce and filtering
  private setupSearch() {
    this.searchTerms
      .pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((term) => {
        this.filterItems(term);
      });
  }

  // Search term change handler
  textChanged(event: Event) {
    const target = event.target as HTMLInputElement;
    this.searchTerms.next(target.value);
    this.searchTerm = target.value;
  }

  // Filter items based on the search term
  private filterItems(term: string) {
    if (term === '') {
      this.filteredItems = [...this.items];
      this.loadData();
    } else {
      this.filteredItems = this.items.filter((item) =>
        item.name.toLowerCase().includes(term.toLowerCase()),
      );
    }
    this.noResultsFound = this.filteredItems.length === 0;
    this.totalItems = this.filteredItems.length;
    this.currentPage = 1;
    this.updateDisplayedItems();
  }

  toggleSelection(item: Item) {
    item.selected = !item.selected;
    this.updateAllSelected();
  }

  toggleAllSelection() {
    this.allSelected = !this.allSelected;
    this.displayedItems.forEach((item) => (item.selected = this.allSelected));
  }

  private updateAllSelected() {
    this.allSelected = this.displayedItems.every((item) => item.selected);
  }

  get hasSelectedItems(): boolean {
    return this.displayedItems.some((item) => item.selected);
  }

  get selectedItemsCount(): number {
    return this.displayedItems.filter((item) => item.selected).length;
  }

  deleteSelectedItems() {
    console.log('Deleting selected items');
    this.items = this.items.filter((item) => !item.selected);
    this.filterItems(this.searchTerm);
    this.allSelected = false;
  }

  // Pagination logic
  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.loadData(); // Load new data for the new page
    }
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.loadData(); // Load new data for the previous page
    }
  }

  private updateDisplayedItems() {
    this.displayedItems = this.filteredItems;
  }

  get totalPages(): number {
    return Math.ceil(this.totalItems / this.itemsPerPage);
  }

  get paginationText(): string {
    const startItem = (this.currentPage - 1) * this.itemsPerPage + 1;
    const endItem = Math.min(
      this.currentPage * this.itemsPerPage,
      this.totalItems,
    );
    return `${startItem} - ${endItem} מתוך ${this.totalItems}`;
  }
}
