import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { sentryProviders } from './common/sentry.provider';
import { SidePanelComponent } from './components/side-panel/side-panel.component';
import { TableComponent } from './pages/leads/table/table.component';
import { SharedService } from './services/shared.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet , SidePanelComponent, TableComponent],
  providers: [...sentryProviders],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'engage-flow-crm-front';
  constructor(private readonly sharedService:SharedService){}
}
