export const environment = {
  isProduction: true,
  backendBaseUrl: 'https://crm.orrgoren.com/api',
  cloudinary: {
    preset: 'flow-cloud',
    folder: 'engage-flow-accounts',
    url: 'https://api.cloudinary.com/v1_1/',
    bucketName: 'dixd0c1ua',
  },
};
