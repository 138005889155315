<div class="integration-card">
  <div>
    <div class="logo-container">
      <img
        [src]="integration.image.src"
        [alt]="integration.image.alt"
        class="logo"
      />
    </div>
    <div class="content">
      <h2>{{ integration.name }}</h2>
      <p>{{ integration.subtitle }}</p>
    </div>
  </div>
  <div class="actions">
    <app-toggle-button
      (toggled)="handleIntegration($event)"
    ></app-toggle-button>
    <button *ngIf="isIntegrationOn">
      <lucide-icon name="settings" size="18px"></lucide-icon>
    </button>
  </div>
</div>
