import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';
export enum TagsType {
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success',
  INFO = 'info',
}
@Component({
  selector: 'app-tag',
  standalone: true,
  imports: [NgClass],
  templateUrl: './tag.component.html',
  styleUrl: './tag.component.scss',
})
export class TagComponent {
  @Input() label: string;
  @Input() type: TagsType = TagsType.INFO;
}
