<div class="table-top-section">
  <div class="table-top-section-right-side">
    <div class="input-wrapper">
      <lucide-icon name="search" size="18px"></lucide-icon>
      <input (input)="textChanged($event)" placeholder="חפש שם ליד..." />
    </div>
    <div *ngIf="hasSelectedItems">
      {{ selectedItemsCount }}
      @if (selectedItemsCount === 1) {
        נבחר
      } @else if (selectedItemsCount > 1) {
        נבחרו
      }
    </div>
  </div>

  <div class="table-top-section-left-side">
    <div class="sub-section sub-section-flex">
      <lucide-icon name="filter" size="18px"></lucide-icon>
      <lucide-icon name="printer" size="18px"></lucide-icon>
      <lucide-icon name="download" size="18px"></lucide-icon>
      <lucide-icon
        *ngIf="hasSelectedItems"
        name="trash-2"
        size="18px"
        (click)="deleteSelectedItems()"
      ></lucide-icon>
    </div>
    <div class="divider"></div>
    <div class="sub-section-flex sub-section">
      <lucide-icon
        name="chevron-right"
        size="18px"
        (click)="previousPage();"
        [ngClass]="{ disabled: currentPage === 1 }"
      ></lucide-icon>
      <lucide-icon
        name="chevron-left"
        size="18px"
        (click)="nextPage();"
        [ngClass]="{ disabled: currentPage === totalPages }"
      ></lucide-icon>
      <div>
        {{ paginationText }}
      </div>
    </div>
  </div>
</div>
<div class="table-wrapper">
  <table>
    <thead>
    <tr>
      <th class="col-checkbox">
        <input
          type="checkbox"
          [checked]="allSelected"
          (change)="toggleAllSelection()"
          [disabled]="isLoading"
        />
      </th>
      <th class="col-name">שם מלא</th>
      <th class="col-email">דוא"ל</th>
      <th class="col-phone">טלפון</th>
      <th class="col-status">סטטוס</th>
      <th class="col-source">מקור</th>
      <th class="col-last-contact">פניה אחרונה</th>
      <th class="col-create-date">תאריך יצירה</th>
      <th class="col-actions">פעולות</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngIf="!isLoading; else loadingTemplate">
      <ng-container *ngIf="!noResultsFound; else noResults">
        <tr
          *ngFor="let item of displayedItems"
          [ngClass]="{ 'selected-row': item.selected }"
        >
          <td class="col-checkbox">
            <input
              type="checkbox"
              [checked]="item.selected"
              (change)="toggleSelection(item)"
            />
          </td>
          <td class="col-name">{{ item.name }}</td>
          <td class="col-email">
            <div
              *ngIf="item.email; else emptyAnswer"
              style="display: flex; gap: 8px"
            >
              <div>
                <a href="mailto:{{ item.email }}">
                  <lucide-icon name="mail" size="18px"></lucide-icon>
                </a>
              </div>
              <div style="line-height: 17px">
                {{ item.email }}
              </div>
            </div>
          </td>
          <td class="col-phone">
            <div
              *ngIf="item.phone; else emptyAnswer"
              style="display: flex; gap: 8px"
            >
              <div>
                <a target="“_blank”" href="https://wa.me/{{ item.phone }}">
                  <lucide-icon name="phone" size="18px"></lucide-icon>
                </a>
              </div>
              <div style="line-height: 17px">
                {{ item.phone }}
              </div>
            </div>
          </td>
          <td class="col-status">
            <app-tag [label]="item.status"></app-tag>
          </td>
          <td class="col-source">
            <app-tag
              [label]="item.source"
              [type]="TagsType.WARNING"
            ></app-tag>
          </td>
          <td class="col-last-contact">לא התבצעה פניה</td>
          <td class="col-create-date">שלשום</td>
          <td class="col-actions">
            <app-table-actions></app-table-actions>
          </td>
        </tr>
      </ng-container>
      <ng-template #noResults>
        <tr>
          <td colspan="9" class="no-results">
            לא נמצאו תוצאות עבור "{{ searchTerm }}"
          </td>
        </tr>
      </ng-template>
    </ng-container>
    <ng-template #loadingTemplate>
      <tr
        *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
        class="loading-row"
      >
        <td *ngFor="let j of [1, 2, 3, 4, 5, 6, 7, 8, 9]">
          <ngx-skeleton-loader
            [theme]="{ height: '20px', width: '80%' }"
          ></ngx-skeleton-loader>
        </td>
      </tr>
    </ng-template>
    </tbody>
  </table>
</div>

<ng-template #emptyAnswer>-</ng-template>
