import { staticSuite, test, only, enforce } from 'vest';
import { isEmail } from 'validator';
import 'vest/enforce/email';
import { LoginFormModel } from '../models/login-form.model';

enforce.extend({ isEmail });

export const loginFormValidations = () => {
  return staticSuite((model: LoginFormModel, field: string) => {
    only(field);

    test('email', 'שדה כתובת דוא"ל הינו שדה חובה', () => {
      enforce(model.email).isNotBlank();
    });

    test('email', 'כתובת הדוא"ל אינה תקינה', () => {
      enforce(model.email).isEmail();
    });

    test('passwords.password', 'שדה הסיסמה הינו שדה חובה', () => {
      enforce(model.password).isNotBlank();
    });
  });
};
