import { Routes } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LeadsComponent } from './pages/leads/leads.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { IntegrationsComponent } from './pages/integrations/integrations.component';
import { SalesComponent } from './pages/sales/sales.component';
import { TasksComponent } from './pages/tasks/tasks.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { LoginComponent } from './pages/login/login.component';
import { SignupComponent } from './pages/signup/signup.component';
import { MainComponent } from './layouts/main/main.component';
import { AuthComponent } from './pages/auth/auth.component';
import { CreateAccountComponent } from './pages/create-account/create-account.component';
import { AuthGuard } from './guards/auth.guard';
import { AccountGuard } from './guards/account.guard';
import { LoginGuard } from './guards/login.guard';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'app',
  },
  {
    path: 'app',
    component: MainComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: 'leads',
        component: LeadsComponent,
      },
      {
        path: 'reports',
        component: ReportsComponent,
      },
      {
        path: 'integrations',
        component: IntegrationsComponent,
      },
      {
        path: 'sales',
        component: SalesComponent,
      },
      {
        path: 'tasks',
        component: TasksComponent,
      },
      {
        path: 'settings',
        component: SettingsComponent,
      },
    ],
  },
  {
    path: 'auth',
    component: AuthComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent,
        canActivate: [LoginGuard],
      },
      {
        path: 'signup',
        component: SignupComponent,
      },
      {
        path: 'create-account',
        component: CreateAccountComponent,
        canActivate: [AccountGuard],
      },
    ],
  },
  {
    path: 'login',
    redirectTo: 'auth/login',
  },
  {
    path: 'signup',
    redirectTo: 'auth/signup',
  },
];
