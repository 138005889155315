<h3>התחבר לחשבון שלך</h3>

<form>
  <form
    scVestForm
    (ngSubmit)="onSubmit()"
    (formValueChange)="formValue.set($event)"
    [formValue]="formValue()"
  >
    <fieldset>
      <div class="input-wrapper" sc-control-wrapper>
        <input
          name="name"
          class="auth-input"
          [ngModel]="formValue().name"
          type="email"
          placeholder="שם החברה"
        />
        <lucide-icon name="mail" [size]="24"></lucide-icon>
      </div>
      <div class="input-wrapper" sc-control-wrapper>
        <input
          name="companyEmail"
          class="auth-input"
          [ngModel]="formValue().companyEmail"
          type="email"
          placeholder="מייל החברה"
        />
        <lucide-icon name="mail" [size]="24"></lucide-icon>
      </div>
      <div class="input-wrapper" sc-control-wrapper>
        <input
          name="companyPhone"
          class="auth-input"
          [ngModel]="formValue().companyPhone"
          type="text"
          placeholder="מס טלפון של החברה"
        />
        <lucide-icon name="phone" [size]="24"></lucide-icon>
      </div>

      <div class="input-wrapper" sc-control-wrapper>
        <div class="file-wrapper">
          <input
            type="file"
            id="file-upload"
            name="accountLogo"
            [ngModel]="formValue().accountLogo"
            (change)="onFileSelected($event)"
          />
          <label for="file-upload">
            בחר לוגו חברה
            <lucide-icon name="upload" [size]="24"></lucide-icon>
          </label>
          @if (loadingPercentage) {
            <div class="image-preload">
              @if (loadingPercentage !== 100) {
                <p>טוען</p>
                <div class="loading-bar">
                  <div
                    class="loading-bar-fill"
                    [style]="{ width: loadingPercentage * 2 + 'px' }"
                  ></div>
                </div>
              }
              @if (cloudinarySrc) {
                <img
                  class="file-preview"
                  [src]="cloudinarySrc"
                  width="150"
                  alt="company logo"
                />
              }
            </div>
          }
        </div>
      </div>

      <input class="submit-btn" type="submit" value="המשך להתחברות" />
    </fieldset>
  </form>
</form>
