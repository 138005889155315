import { Component, OnInit, signal } from '@angular/core';
import { AuthBannerComponent } from '../../components/auth-banner/auth-banner.component';
import { LucideAngularModule } from 'lucide-angular';
import { FormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { NgTemplateOutlet } from '@angular/common';
import { loginFormValidations } from '../../validations/login-form.validations';
import { LoginFormModel } from '../../models/login-form.model';
import { vestForms } from 'ngx-vest-forms';
import { AuthService } from '../../services/auth.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    FormsModule,
    AuthBannerComponent,
    LucideAngularModule,
    RouterLink,
    NgTemplateOutlet,
    vestForms,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit {
  protected readonly suite = loginFormValidations();
  protected readonly formValue = signal<LoginFormModel>({});

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    if (this.authService.isAuthenticated()) {
      this.router.navigate(['/app']);
    }
  }

  backendBaseUrl = environment.backendBaseUrl;

  onSubmit() {
    const form = this.formValue();
    this.authService
      .credentialsLogin({
        email: form.email,
        password: form.password,
      })
      .subscribe({
        next: (loggedIn: boolean) => {
          if (!loggedIn) this.router.navigate(['/login']);
          this.router.navigate(['/app/dashboard']).catch((err) => {
            console.error('Navigation error: ', err);
          });
        },
        error: (error: any) => {
          console.error(error);
          this.router.navigate(['/login']);
        },
        complete: () => {
          console.log('Login process complete');
        },
      });
  }
}
