import { Component, inject, signal } from '@angular/core';
import { AuthBannerComponent } from '../../components/auth-banner/auth-banner.component';
import { LucideAngularModule } from 'lucide-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { createSignUpFormValidations } from '../../validations/signup-form.validations';
import { SignUpFormModel } from '../../models/signup-form.model';
import { CommonModule } from '@angular/common';
import { UserService } from '../../services/user.service';
import { vestForms } from 'ngx-vest-forms';
import { AuthService } from '../../services/auth.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-signup',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    AuthBannerComponent,
    LucideAngularModule,
    ReactiveFormsModule,
    RouterLink,
    vestForms,
  ],
  templateUrl: './signup.component.html',
  styleUrl: './signup.component.scss',
})
export class SignupComponent {
  backendBaseUrl = environment.backendBaseUrl;

  protected readonly suite = createSignUpFormValidations();
  protected readonly formValue = signal<SignUpFormModel>({});
  protected readonly validationConfig: {
    [key: string]: string[];
  } = { 'passwords.password': ['passwords.confirmPassword'] };
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
  ) {}

  onSubmit() {
    const form = this.formValue();

    const signupPayload = {
      name: form?.fullName,
      email: form?.email,
      password: form?.passwords?.password,
    };

    this.authService.credentialsSignup(signupPayload).subscribe(
      (response: any) => {
        if (response?.data?.user) {
          this.router.navigate(['/auth/create-account']);
        }
      },
      (error: any) => {
        console.log(error);
      },
    );
  }
}
