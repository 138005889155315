import { assign as n, bindNot as t, isStringValue as e, numberEquals as r, greaterThan as s, isArray as u, isNumeric as o, isNullish as i, isBoolean as a, isEmpty as c, isNotArray as f, isNotEmpty as l, isNotNull as N, isNotNullish as m, isNotNumeric as g, isNotUndefined as h, isNull as p, isPositive as d, isUndefined as y, lengthEquals as E, lengthNotEquals as v, longerThan as O, numberNotEquals as q, defaultTo as x, optionalFunctionValue as T, invariant as b, StringObject as w, mapFirst as B } from "vest-utils";
import { createCascade as W } from "context";
const S = W((t, e) => {
  const r = {
    value: t.value,
    meta: t.meta || {}
  };
  return e ? t.set ? n(r, {
    parent: () => function (n) {
      return {
        value: n.value,
        meta: n.meta,
        parent: n.parent
      };
    }(e)
  }) : e : n(r, {
    parent: P
  });
});
function P() {
  return null;
}
function k(n, t) {
  return e(n) && e(t) && n.endsWith(t);
}
const A = t(k);
function I(n, t) {
  return n === t;
}
const J = t(I);
function K(n, t) {
  return r(n, t) || s(n, t);
}
function R(n, t) {
  return (u(t) || !(!e(t) || !e(n))) && -1 !== t.indexOf(n);
}
const U = t(R);
function V(n, t) {
  return o(n) && o(t) && Number(n) < Number(t);
}
function $(n, t) {
  return r(n, t) || V(n, t);
}
function F(n, t, e) {
  return K(n, t) && $(n, e);
}
const M = t(F);
function X(n) {
  return i(n) || e(n) && !n.trim();
}
const j = t(X),
  z = t(a),
  C = n => !!o(n) && n % 2 == 0;
function D(n, t) {
  return n in t;
}
const G = t(D);
function H(n) {
  return Number.isNaN(n);
}
const L = t(H);
function Q(n) {
  return V(n, 0);
}
function Y(n) {
  return Boolean("number" == typeof n);
}
const Z = t(Y),
  _ = n => !!o(n) && n % 2 != 0,
  nn = t(e);
function tn(n) {
  return !!n;
}
const en = t(tn);
function rn(n, t) {
  if (i(t)) return !1;
  for (const e in t) if (t[e] === n) return !0;
  return !1;
}
const sn = t(rn);
function un(n, t) {
  return K(n.length, t);
}
function on(n, t) {
  return t instanceof RegExp ? t.test(n) : !!e(t) && new RegExp(t).test(n);
}
const an = t(on);
function cn(n, t) {
  try {
    return t(n);
  } catch (n) {
    return !1;
  }
}
function fn(n, t) {
  return V(n.length, t);
}
function ln(n, t) {
  return $(n.length, t);
}
function Nn(n, t) {
  return e(n) && e(t) && n.startsWith(t);
}
const mn = t(Nn);
const gn = {
  condition: cn,
  doesNotEndWith: A,
  doesNotStartWith: mn,
  endsWith: k,
  equals: I,
  greaterThan: s,
  greaterThanOrEquals: K,
  gt: s,
  gte: K,
  inside: R,
  isArray: u,
  isBetween: F,
  isBlank: X,
  isBoolean: a,
  isEmpty: c,
  isEven: C,
  isFalsy: en,
  isKeyOf: D,
  isNaN: H,
  isNegative: Q,
  isNotArray: f,
  isNotBetween: M,
  isNotBlank: j,
  isNotBoolean: z,
  isNotEmpty: l,
  isNotKeyOf: G,
  isNotNaN: L,
  isNotNull: N,
  isNotNullish: m,
  isNotNumber: Z,
  isNotNumeric: g,
  isNotString: nn,
  isNotUndefined: h,
  isNotValueOf: sn,
  isNull: p,
  isNullish: i,
  isNumber: Y,
  isNumeric: o,
  isOdd: _,
  isPositive: d,
  isString: e,
  isTruthy: tn,
  isUndefined: y,
  isValueOf: rn,
  lengthEquals: E,
  lengthNotEquals: v,
  lessThan: V,
  lessThanOrEquals: $,
  longerThan: O,
  longerThanOrEquals: un,
  lt: V,
  lte: $,
  matches: on,
  notEquals: J,
  notInside: U,
  notMatches: an,
  numberEquals: r,
  numberNotEquals: q,
  shorterThan: fn,
  shorterThanOrEquals: ln,
  startsWith: Nn
};
function hn(n) {
  return gn[n];
}
function pn(n, t) {
  const e = {
    pass: n
  };
  return t && (e.message = t), e;
}
function dn(n) {
  return x(n, pn(!0));
}
function yn(n, t, e, ...r) {
  return function (n) {
    b(a(n) || n && a(n.pass), "Incorrect return value for rule: " + JSON.stringify(n));
  }(n), a(n) ? pn(n) : pn(n.pass, T(n.message, t, e, ...r));
}
function En(n) {
  const t = {
    message: function (n) {
      return e = n, r;
    },
    pass: !1
  };
  let e;
  const r = new Proxy(t, {
    get: (s, u) => {
      const o = hn(u);
      return o ? function (t, r, s) {
        return function (...u) {
          const o = S.run({
            value: n
          }, () => yn(r(n, ...u), s, n, ...u));
          function a() {
            return i(e) ? i(o.message) ? `enforce/${s} failed with ${JSON.stringify(n)}` : w(o.message) : w(e);
          }
          return b(o.pass, a()), t.pass = o.pass, t;
        };
      }(r, o, u) : t[u];
    }
  });
  return r;
}
const vn = function () {
  const t = {
    context: () => S.useX(),
    extend: t => {
      n(gn, t);
    }
  };
  return new Proxy(n(En, t), {
    get: (n, t) => t in n ? n[t] : hn(t) ? function (n) {
      const t = [];
      let e;
      return function n(r) {
        return (...s) => {
          const u = hn(r);
          t.push(n => yn(u(n, ...s), r, n, ...s));
          let o = {
            run: n => dn(B(t, (t, r) => {
              var s;
              const u = S.run({
                value: n
              }, () => t(n));
              r(!u.pass, pn(!!u.pass, null !== (s = T(e, n, u.message)) && void 0 !== s ? s : u.message));
            })),
            test: n => o.run(n).pass,
            message: n => (n && (e = n), o)
          };
          return o = new Proxy(o, {
            get: (t, e) => hn(e) ? n(e) : t[e]
          }), o;
        };
      }(n);
    }(t) : void 0
  });
}();
export { S as ctx, vn as enforce };
