import { Component, OnInit, signal } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RouterModule } from '@angular/router';
import { headerConfig } from './header.config';
import { LucideAngularModule } from 'lucide-angular';
import {
  SidePanelComponent,
  userDetails,
} from '../side-panel/side-panel.component';
import { AvatarComponent } from '../avatar/avatar.component';
import { SharedService } from '../../services/shared.service';
import { DeviceSize } from '../../models/device-size.model';
import { HeaderMobileDropdownComponent } from './header-mobile-dropdown/header-mobile-dropdown.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    NgOptimizedImage,
    LucideAngularModule,
    HeaderMobileDropdownComponent,
    AvatarComponent,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  config = headerConfig;
  avatar = signal<userDetails>({
    src: 'https://robohash.org/147.235.195.75.png',
    userName: 'משה כהן',
    email: 'moshe.cohen@gmail.com',
  });

  sizes!: DeviceSize;

  constructor(private readonly sharedService: SharedService) {}

  ngOnInit(): void {
    console.log(this.sharedService.sizes);
    this.sizes = this.sharedService.sizes;
  }

  isSidePanelOpen = signal(false);

  toggleSidePanel() {
    this.isSidePanelOpen.set(!this.isSidePanelOpen());
  }

  closeDropdown(event: boolean) {
    this.isSidePanelOpen.set(event);
  }
}
