import {
  ApplicationConfig,
  importProvidersFrom,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  Bell,
  Blocks,
  BookUser,
  CircleChevronRight,
  ClipboardPlus,
  Cog,
  Handshake,
  Home,
  ListTodo,
  LockKeyhole,
  LogOut,
  LucideAngularModule,
  Mail,
  Search,
  Upload,
  User,
  Share,
  Trash2,
  Pencil,
  Filter,
  Printer,
  Download,
  ChevronLeft,
  ChevronRight,
  Settings,
  Phone,
  Menu,
} from 'lucide-angular';
import { ReactiveFormsModule } from '@angular/forms';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import { CloudinaryModule } from '@cloudinary/ng';
import { authTokenInterceptor } from './interceptors/auth-token.interceptor';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideAnimationsAsync(),
    ReactiveFormsModule,
    CloudinaryModule,
    importProvidersFrom(
      LucideAngularModule.pick({
        Search,
        Home,
        BookUser,
        ClipboardPlus,
        ListTodo,
        Handshake,
        Blocks,
        Cog,
        Bell,
        LogOut,
        CircleChevronRight,
        Mail,
        User,
        LockKeyhole,
        Upload,
        Share,
        Trash2,
        Pencil,
        Filter,
        Printer,
        Download,
        ChevronLeft,
        ChevronRight,
        Settings,
        Phone,
        Menu
      }),
    ),
    provideHttpClient(withInterceptors([authTokenInterceptor])),
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
  ],
};
