<div class="separator">
  <h3>הרשמה ויצירת חשבון</h3>
  <form
    scVestForm
    (ngSubmit)="onSubmit()"
    [validationConfig]="validationConfig"
    (formValueChange)="formValue.set($event)"
    [formValue]="formValue()"
    [suite]="suite"
  >
    <fieldset>
      <div class="input-wrapper" sc-control-wrapper>
        <input
          name="fullName"
          class="auth-input"
          [ngModel]="formValue().fullName"
          type="text"
          placeholder="שם מלא"
        />
        <lucide-icon name="user" [size]="24"></lucide-icon>
      </div>
      <div class="input-wrapper" sc-control-wrapper>
        <input
          name="email"
          class="auth-input"
          [ngModel]="formValue().email"
          [validationOptions]="{ debounceTime: 300 }"
          type="email"
          placeholder="אימייל"
        />
        <lucide-icon name="mail" [size]="24"></lucide-icon>
      </div>
      <div class="modelGroup" ngModelGroup="passwords" sc-control-wrapper>
        <div class="input-wrapper" sc-control-wrapper>
          <input
            name="password"
            class="auth-input"
            [ngModel]="formValue().passwords?.password"
            type="password"
            placeholder="סיסמא"
          />
          <lucide-icon name="lock-keyhole" [size]="24"></lucide-icon>
        </div>
        <div class="input-wrapper" sc-control-wrapper>
          <input
            name="confirmPassword"
            class="auth-input"
            [ngModel]="formValue().passwords?.confirmPassword"
            type="password"
            placeholder="אימות סיסמה"
          />
          <lucide-icon name="lock-keyhole" [size]="24"></lucide-icon>
        </div>
      </div>
      <div class="input-wrapper" sc-control-wrapper>
        <div class="checkbox-wrapper">
          <input
            type="checkbox"
            id="terms"
            name="terms"
            [ngModel]="formValue().terms"
          />
          <label for="terms"
            >ע"י פתיחת החשבון אתה מצהיר כי קראת ואתה מאשר את
            <a href="#">תנאי השימוש</a> ואת
            <a href="#">מדיניות הפרטיות</a> שלנו.</label
          >
        </div>
      </div>

      <input class="submit-btn" type="submit" value="המשך לפתיחת חשבון" />
    </fieldset>
  </form>

  <div class="or-signup-with">
    <p class="lines">או הרשם עם</p>
    <div class="oauth-btn-wrapper">
      <a class="oauth-btn" [href]="backendBaseUrl + '/auth/google/connect'">
        <img src="/assets/google-logo.svg" alt="google logo" />גוגל
      </a>
      <a class="oauth-btn">
        <img src="/assets/facebook-logo.svg" alt="facebook logo" />פייסבוק
      </a>
    </div>
    <p class="login-link">
      כבר יש לך חשבון?
      <a routerLink="/auth/login" class="link">לחץ כאן להתחברות</a>
    </p>
  </div>
</div>
