import { Component } from '@angular/core';
import {
  IntegrationComponent,
  Integration,
} from './components/integarion/integration.component';

@Component({
  selector: 'app-integrations',
  standalone: true,
  imports: [IntegrationComponent],
  templateUrl: './integrations.component.html',
  styleUrl: './integrations.component.scss',
})
export class IntegrationsComponent {
  mockIntegrations: Integration[] = [
    {
      name: 'Meta',
      subtitle: 'חבר את החשבון העסקי שלך של Meta על מנת לקבל לידים למערכת.',
      image: {
        src: 'assets/meta-logo.svg',
        alt: 'meta logo',
      },
    },
    {
      name: 'סיילספורס',
      subtitle: 'סיילספורס היא פלטפורמת ניהול קשרי לקוחות (CRM) מבוססת ענן.',
      image: {
        src: 'assets/google-logo.svg',
        alt: 'לוגו סיילספורס',
      },
    },
    {
      name: 'סלאק',
      subtitle:
        'סלאק היא פלטפורמת תקשורת עסקית קניינית שפותחה על ידי סלאק טכנולוגיות.',
      image: {
        src: 'assets/google-logo.svg',
        alt: 'לוגו סלאק',
      },
    },
    {
      name: 'Google Workspace',
      subtitle: 'Google Workspace הוא אוסף של שירותי מחשוב ענן',
      image: {
        src: 'assets/google-logo.svg',
        alt: 'Google Workspace לוגו',
      },
    },
    {
      name: 'Microsoft 365',
      subtitle: 'Microsoft 365 הוא קו של שירותי מנוי המוצעים על ידי מיקרוסופט.',
      image: {
        src: 'assets/google-logo.svg',
        alt: 'Microsoft 365 לוגו',
      },
    },
  ];
}
